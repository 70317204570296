import React from "react"
import { Link } from "gatsby"
import smallLogo from "../images/smallLogo.png"

export default function Navigation() {
  return (
    <div className="container">
        <nav className="navbar navbar-light navbar-expand-md" aria-label="Navigation Bar">
            <div className="container-fluid">
                <Link to="/?showAnimation=false">
                    <img src={ smallLogo } alt="Burtle Web Solutions Header Logo" width="128" height="128"></img>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#bwsNavbar" aria-controls="bwsNavbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="bwsNavbar">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item px-4">
                            <Link to="/About/" className="nav-link text-dark fs-4">About</Link>
                        </li>
                        <li className="nav-item px-4">
                            <Link to="/Services/" className="nav-link text-dark fs-4">Services</Link>
                        </li>
                        <li className="nav-item px-4">
                            <Link to="/Portfolio/" className="nav-link text-dark fs-4">Clients</Link>
                        </li>
                        <li className="nav-item px-4">
                            <Link to="/Contact/" className="nav-link text-dark fs-4">Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
  )
}