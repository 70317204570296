import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className="mb-3">
        <div className="container d-flex flex-column align-items-center">
            <div>
              <a href="https://www.facebook.com/burtlewebservices" target="_blank">
                <img className="socialMediaIcon" alt="Facebook Icon" src={'/images/facebook.png'}></img>
              </a>
            </div>
            <div>&copy; Copyright { (new Date()).getFullYear() } Burtle Web Services LLC</div>
        </div>
    </footer>
  )
}