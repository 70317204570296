import React from "react"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"

export default function Layout({ children }) {
  return (
    <div className="site-content">
      <Navigation />
      <main>
        <title>Burtle Web Services - Chatham, IL</title>
        <div>
            { children }
        </div>
      </main>
      <Footer />
    </div>
  )
}